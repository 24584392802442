div.DraftEditor-root {
    background-color: #eaeff0;
    border-color: #fcd9d9;
    border-width: 2px;
    padding-left: 5px;
    padding-top: -40px;
    margin-top: -25px;
    border-left-style: none;
   }
   ::selection {
    background: rgb(194, 176, 214);
  }  

 
  
